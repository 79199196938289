export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 80 },
    { label: "订单状态", prop: "status", slots: "status" },
    { label: "openid", prop: "openid" },
    { label: "手机号", prop: "phone", width: 130 },
    { label: "金额", prop: "money", width: 50 },
    { label: "订单描述", prop: "msg" },
    {
      label: "订单创建时间",
      prop: "createdTime",
      width: 180,
      // slots: "state",
    }
  ];
}
