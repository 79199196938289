<template>
  <ul class="tableTab">
    <li
      v-for="(item, index) in tabData"
      :key="index"
      @click="itemBtn(item, index)"
    >
      <div
        class="title"
        :class="{
          active: active === index,
        }"
      >
        {{ item.title }}
        <div class="line" v-if="active === index"></div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "TableTab",
  components: {},
  props: {
    tabData: {
      type: Array,
    },
  },
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    itemBtn(item, index) {
      console.log(item, index);
      this.$emit("clickTableTab", item);
      this.active = index;
    },
  },
};
</script>

<style scoped lang="less">
.tableTab {
  // border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  text-align: 40px;
  position: relative;
  color: #787878;
  display: flex;
  align-items: center;
  padding: 15px 0 0;
  font-weight: 600;
}
.tableTab li {
  height: 40px;
  font-size: 14px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.tableTab li:hover {
  cursor: pointer;
  color: #1d6aff;
}

li {
  color: rgba(0, 0, 0, 0.65);
}
li > .title {
  // border: 1px solid red;
  position: relative;
}
.line {
  height: 2px;
  width: 100%;
  background-color: #1d6aff;
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.active {
  background: #ffffff !important;
  color: #1d6aff !important;
}
</style>
