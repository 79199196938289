<!-- 审核 -->
<template>
  <div class="diseaseList">
    <HeaderTab :tabData="tabData" @clickTableTab="clickTableTab" style="margin: 0 0 40px;"/>
    <el-card class="box-card" v-if="active === 0">
      <div slot="header" class="clearfix">
        <span>有效期至 2024-01-01</span>
        <el-button style="float: right; padding: 3px 0" type="text"
          >续费</el-button
        >
      </div>
      <div>
        尊享版
      </div>
    </el-card>
    <div class="tableList" v-else>
      <!-- 封装表单table组件 -->
      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <template slot="status" slot-scope="scope">
          <FillingStatus :value="scope.row.status" />
        </template>
        <!--  <template slot="fillRate" slot-scope="scope">
          <Percentage :value="scope.row"></Percentage>
        </template> -->
        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <el-button size="mini" type="text" @click="handleEdit(row)" primary
              >编辑</el-button
            >
            <el-button size="mini" type="text" @click="handleDelete(row)"
              >删除</el-button
            >
          </div>
        </template>
      </ElTable>
    </div>
    <!-- 弹窗表单 -->
    <el-dialog
      :title="dialogName"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="dialogForm" label-width="80px">
        <el-form-item label="订单状态:">
          <el-select
            v-model="dialogForm.status"
            placeholder="请选择"
            style="width: 100%;"
            clearable
          >
            <div v-for="(item, index) in roleList" :key="index">
              <el-option :label="item.name" :value="item.id"></el-option>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item label="openid">
          <el-input clearable v-model="dialogForm.openid"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input clearable v-model="dialogForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="金额">
          <el-input clearable v-model="dialogForm.money"></el-input>
        </el-form-item>
        <el-form-item label="订单描述">
          <el-input
            type="textarea"
            clearable
            v-model="dialogForm.msg"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancelBtn" size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button class="addBtn" size="small" type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HeaderTab from "@/components/tab/headerTab/index.vue";
import ElTable from "@/components/elTable/index.vue";
import listHeader from "./listHeader";
import Percentage from "@/components/filters/Percentage.vue";
import FillingStatus from "@/components/filters/FillingStatus.vue";
import KindTab from "@/components/tab/kindTab/index.vue";
// import waves from "@/directive/waves/index.js"; // 水波纹指令
import {
  addOrder,
  updateOrder,
  selectOrder,
  deleteOrder,
} from "@/services/mall/order";
export default {
  components: {
    HeaderTab,
    ElTable,
    Percentage,
    FillingStatus,
    KindTab,
  },
  async created() {
    this.getListData();
  },
    // directives: {
  //   waves,
  // },
  data() {
    return {
      active: 0,
      tabData: [
        { id: 0, title: "立即续费" },
        { id: 1, title: "订单记录" },
      ],
      loading: false,
      listQuery: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      /* 表格数据 */
      tableData: [],
      /* 弹窗 */
      dialogName: "",
      dialogVisible: false,
      dialogForm: {},
      roleList: [
        { id: 0, name: "待处理" },
        { id: 1, name: "已处理" },
      ],
    };
  },
  computed: {
    listHeader,
  },
  watch: {},
  methods: {
    clickTableTab(e) {
      console.log(e);
      this.active = e.id;
    },
    async getListData(listQuery) {
      this.loading = true;
      const { data, code, count } = await selectOrder(
        listQuery || this.listQuery
      );
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },
    /* 搜索按钮 */
    async search(listQuery) {
      console.log("listQuery", listQuery);
      this.listQuery = listQuery;
      this.getListData();
    },

    /* 新增 */
    handleAdd() {
      this.dialogVisible = true;
      this.dialogName = "创建订单";
      this.dialogForm = {};
    },

    /* 修改 */
    handleEdit(row) {
      console.log(row);
      this.dialogVisible = true;
      this.dialogName = "编辑订单";
      this.dialogForm = JSON.parse(JSON.stringify(row));
    },

    /* 保存 */
    save() {
      if (this.dialogName === "创建订单") {
        addOrder(this.dialogForm).then((res) => {
          console.log("创建订单", res);
          if (res.code === 200) {
            this.listQuery.pageNumber = 1;
            this.getListData();
            this.$message({
              type: "success",
              message: "创建成功!",
            });
            this.dialogVisible = false;
          }
        });
      }
      if (this.dialogName === "编辑订单") {
        updateOrder(this.dialogForm).then((res) => {
          console.log("编辑订单", res);
          if (res.code === 200) {
            this.getListData();
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
            this.dialogVisible = false;
          }
        });
      }
    },

    /* 删除 */
    handleDelete(row) {
      console.log(row.id);
      this.$confirm("此操作将永久删除该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(row.id);
          deleteOrder({ id: row.id }).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getListData();
          });
        })
        .catch(() => {});
    },

    /* 分页 */
    handleCurrentChange(e) {
      console.log(e);
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      console.log(e);
      this.listQuery.pageSize = e;
      this.getListData();
    },
  },
};
</script>

<style scoped lang="less">
.diseaseList {
  width: 100%;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}

.tableList {
  border-radius: 6px;
  background: #fff;
  // max-width: 1405px;
  margin: 10px auto;
}

.operation {
  a {
    margin-right: 10px;
  }
}
</style>
