import axios from "axios";
import { request } from "@/utils/request";

/**
 * 查询
 * @param {*} data
 * @returns
 */
export function selectOrder(data) {
  return request({
    url: `/admin/order/selectOrder`,
    method: "POST",
    data,
  });
}

/**
 * 更改
 * @param {*} data
 * @returns
 */
export function updateOrder(data) {
  return request({
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded",
    // },
    url: `/admin/order/update`,
    method: "POST",
    data,
  });
}

/**
 * 删除
 * @param {*} data
 * @returns
 */
export function deleteOrder(data) {
  return request({
    url: `/admin/order/delete`,
    method: "POST",
    data,
  });
}


/**
 * 开票
 * @param {*} data
 * @returns
 */
export function updateInvoiceUrl(data) {
  return request({
    url: `/admin/order/updateInvoiceUrl`,
    method: "POST",
    data,
  });
}
